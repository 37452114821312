<template>
  <v-container class="bg-gray vh-full pd20 lynn-web-in">
    <div class="web-side"></div>
    <div class="location-box np">
      <div class="web-logo">
        <Weblogo width="55" height="30" />
      </div>
      <a @click="$router.go(-1)" class="back">
        <Back />
      </a>
      <strong class="lo-title">스마트린 회원이신가요?</strong>
    </div>
    <v-card max-width="750" class="mx-auto bg-gray nops">
      <v-card-text>
        <WeblogoM width="140" height="80" style="margin:20px 0 50px;"/>
        <v-form>
          <v-text-field v-model.trim="email" label="아이디(이메일)" required></v-text-field>
          <v-text-field
            @keyup.enter="signIn()"
            v-model.trim="password"
            label="비밀번호"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            required
            ref="password"
          ></v-text-field>
          <v-card-text class="error--text lh18" v-if="errMsg != ''">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              class="mr3 v_t"
            >
              <g id="그룹_73" data-name="그룹 73" transform="translate(-301 -229)">
                <circle
                  id="타원_23"
                  data-name="타원 23"
                  cx="1"
                  cy="1"
                  r="1"
                  transform="translate(309 241)"
                  fill="red"
                />
                <rect
                  id="사각형_136"
                  data-name="사각형 136"
                  width="2"
                  height="7"
                  rx="1"
                  transform="translate(309 233)"
                  fill="red"
                />
                <g
                  id="타원_24"
                  data-name="타원 24"
                  transform="translate(301 229)"
                  fill="none"
                  stroke="red"
                  stroke-width="2"
                >
                  <circle cx="9" cy="9" r="9" stroke="none" />
                  <circle cx="9" cy="9" r="8" fill="none" />
                </g>
              </g>
            </svg>
            {{ errMsg }}
          </v-card-text>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn color="primary" class="fts16 ftw7 mw-400 pwidth100" depressed min-height="50" @click="signIn()"
          >로그인</v-btn
        >
      </v-card-actions>

      <v-card-subtitle class="fts14 ftw5 RowBox">
        <v-btn class="fts14 ftw5" text @click="openFindEmail()">아이디 찾기</v-btn>
        <span class="center"></span>
        <v-btn class="fts14 ftw5" text :to="{ path: 'forgetPassword' }">비밀번호 재설정</v-btn>
        </v-card-subtitle
      >
      
      <v-card-actions class="justify-center">
        <v-btn class="MainBtn fts16 ftw7 mw-400 pwidth100" depressed min-height="50" :to="{path: 'signupAgreement' }"
          >회원가입</v-btn>
      </v-card-actions>
    </v-card>

    <div class="text-center" v-if="loadingBar">
      <v-progress-circular
        fullscreen
        indeterminate
        color="red"
        style="position: fixed; top: calc(50% - 16px); left: calc(50% - 16px)"
      ></v-progress-circular>
    </div>
    <FindEmailResultDialog :data="findEmail" @close="findEmail.dialog = false" @login="loginAs" />

    <!-- <div class="loading-box" v-show="hasError">
      <div class="ldb-inbox">
        <strong class="ldb-text">
          [중요공지] 시스템 장애 안내
          <br>
          <br>
          현재 신규 회원가입, 로그인 및 인증 서비스 등에서 장애가 발생하여 일부 서비스 이용이 제한되고
          있습니다.현재 기술팀이 신속히 문제를 파악하여 조치 중에 있으며, 빠른 시간 내에 정상적인 서비스
          이용이 가능하도록 처리중입니다.서비스 이용에 불편을 드려 진심으로 죄송합니다.문의사항은 아래
          연락처로 연락 부탁드립니다.
          <br>
          <br>
          - 기술 지원: 02 - 557 - 6575

        </strong>
        <v-btn class="fts16 ftw5 mt20" color="gery" @click="hasError = false">닫 기</v-btn>
      </div>
    </div> -->
    <div class="new-componant" v-if="is_app && is_development">
      <span class="addset" @click="openDialog = true">관리자 로그인</span>
    </div>

    <!--관리자 로그인 팝업-->
    <v-dialog v-model="openDialog" max-width="360">
      <div class="consetNewBox">
        <div class='effectBox'>
          <div class='wave -one'></div>
          <div class='wave -two'></div>
          <div class='wave -three'></div>
          <div class='title'><Weblogo width="100"/></div>
        </div>
        <div class="cnBox">
          <button @click="movetoCSM()">우미 관리자 로그인</button>
          <button @click="movetoPartner()">협력업체 로그인</button>
        </div>
      </div>
    </v-dialog>
  </v-container>
</template>

<script>
import Back from "@/assets/images/icon/icon-back.svg";
import Weblogo from "@/assets/images/common/new-logo.svg";
import WeblogoM from "@/assets/images/common/new-logoM.svg";
import FindEmailResultDialog from "@/components/User/FindEmailResultDialog";
import { loadNICEMainWithNoToken } from "@/api/NICE_id.js";

export default {
  components: { Back, Weblogo, WeblogoM, FindEmailResultDialog },
  name: "LoginPage",
  data() {
    return {
      openDialog: false, // 2024.09 bong new

      showPassword: false,
      valid: false,
      password: "",
      email: "",
      errMsg: "",
      loadingBar: false,
      fcm_token: "",
      hasError: true,
      findEmail: {
        dialog: false,
        items: [
          { email: "123451234512345@wikey.com" },
          { email: "123451234512345@wikey.com" },
          { email: "123451234512345@wikey.com" },
          { email: "123451234512345@wikey.com" },
          { email: "123451234512345@wikey.com" },
        ],
        msg: "'01012345678'로 등록된 아이디가 없습니다.",
      },
    };
  },
  computed: {
    browserInfo() {
      return navigator.userAgent.toLowerCase();
    },
    userState() {
      return this.$store.getters.getUserState;
    },
    is_android() {
      return this.browserInfo.indexOf("android") > 0;
    },
    is_ios() {
      return this.browserInfo.indexOf("iphone") > 0 || this.browserInfo.indexOf("ipad") > 0;
    },
    is_app() {
      return this.browserInfo.indexOf("wikey_flutter") > 0;
    },
    is_development() {
      return process.env.NODE_ENV !== "production";
    },
  },
  methods: {
    getFcmToken(token) {
      return new Promise((resolve, reject) => {
        window.flutter_inappwebview.callHandler("getAppInfo", null).then(function(result) {
          var json = JSON.parse(result);
          console.log("####fcm_token : " + json.fcm_token);
          token = json.fcm_token;

          resolve(token);
        });
      });
    },
    signIn() {
      if (this.email == "") {
        this.errMsg = "아이디를 입력하세요.";
      } else if (!/.+@.+/.test(this.email)) {
        this.errMsg = "아이디는 이메일 형식이어야합니다.";
      } else if (this.password == "") {
        this.errMsg = "비밀번호를 입력하세요.";
      } else {
        this.loadingBar = true;

        console.log("############# fcm_token signIn() : " + this.fcm_token);
        this.$store
          .dispatch("authenticateUser", {
            email: this.email,
            password: this.password,
            fcm_token: this.fcm_token,
          })
          .then((result) => {
            this.loadingBar = false;
            if (
              this.$route.query.redirect != undefined &&
              this.$route.query.redirect != "/mypage"
            ) {
              this.$router.push({
                path: this.$route.query.redirect,
              });
            } else {
              this.$router.push({ name: "intro" });
            }
          })
          .catch((err) => {
            this.loadingBar = false;

            // Logging err
            this.$store.dispatch("LOGGING", {
              error: err,
              tags: `LoginPage|cognitoAPI|authenticateUser(email=${this.email})`,
              etc: {}, // Put anythings you want to log as object
            });

            if (err.code == "UserNotFoundException" || err.code == "InvalidPasswordException") {
              this.errMsg = "아이디 혹은 비밀번호가 올바르지 않습니다.";
            } else if (err.code == "UserNotConfirmedException") {
              this.$store.commit("ERROR", "인증되지 않은 회원입니다. 인증 번호를 확인해주세요.");
              this.$router.push({
                name: "confirm",
                params: { email: this.email, userData: null },
              });
            } else if (err.code == "NotAuthorizedException") {
              this.errMsg = "이메일 또는 비밀번호가 잘못되었습니다.";
            } else {
              this.errMsg = "로그인할 수 없습니다. 관리자에게 문의해주세요.";
            }
          });
      }
    },
    openFindEmailResult(msg, data) {
      this.findEmail.msg = msg;
      this.findEmail.items = data || [];
      this.findEmail.dialog = true;
    },
    loginAs(email) {
      this.email = email;
      this.findEmail.dialog = false;
    },
    openFindEmail() {
      this.loadingBar = true;
      let form = {
        redirect: "login",
        type: "find_user_email",
      };
      loadNICEMainWithNoToken(form)
        .then((re) => {
          this.loadingBar = false;
          this.nice_form = re.data;
          this.OpenPage();
          this.close();
        })
        .catch((er) => {
          this.loadingBar = false;
          this.$store.commit("ERROR", "본인인증 호출에 실패하였습니다.");
        });
    },
    OpenPage() {
      // window.open(
      //   "",
      //   "_self",
      //   "width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no"
      // );

      let form = document.createElement("form");

      var EncodeData = document.createElement("input");
      EncodeData.type = "hidden";
      EncodeData.name = "EncodeData";
      EncodeData.value = this.nice_form.enc_data;

      var m = document.createElement("input");
      m.type = "hidden";
      m.name = "m";
      m.value = "checkplusSerivce";

      form.action = "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
      form.target = "_self";
      form.appendChild(EncodeData);
      form.appendChild(m);

      document.body.appendChild(form);
      form.submit();
    },
    urlCheck() {
      if (this.$route.query.type) {
        let type = this.$route.query.type;
        let msg = this.$route.query.msg;
        let data = this.$route.query.data;
        if (data) {
          data = this.$route.query.data.split(",");
        }

        this.$router.push(this.$route.path);
        this.openFindEmailResult(msg, data);
      }
    },
    movetoCSM() {
      window.flutter_inappwebview.callHandler("gotoCSM").then(function(result) {
        this.loadingBar = false;
        console.log("@@@ flutterInAppWebViewPlatformReady gotoCSM  result: ", result);
      });
    },
    movetoPartner() {
      window.flutter_inappwebview.callHandler("gotoPartner").then(function(result) {
        this.loadingBar = false;
        console.log("@@@ flutterInAppWebViewPlatformReady gotoPartner  result: ", result);
      });
    },
  },
  created() {
    this.urlCheck();

    this.$store.dispatch("signOut", {});
    this.$store.commit("INFO_UPDATE", {
      type: "onetimeLoad",
      text: true,
    });

    // 앱으로 온 경우 fcm_token 저장
    let browserInfo = navigator.userAgent.toLowerCase();
    if (browserInfo.indexOf("wikey_flutter") > 0) {
      this.getFcmToken().then((result) => {
        this.fcm_token = result;
      });
    }
  },
};
</script>

<style scoped>
.loading-box {
  display: flex;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 60;
  background-color: rgba(255, 255, 255, 0.35);
  justify-content: center;
  align-items: center;
}

.ldb-inbox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 35px 50px;
  background-color: #ffffff;
  border-radius: 30px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.15);
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: #ff3d00;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  margin-bottom: 15px;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
